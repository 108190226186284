import { Close, PhotoSizeSelectActual } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, Modal, Typography } from "@mui/material";
import { collection, getDocs } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';
import { firestore } from '../../../../firebase';

interface ProfileProps {
  id: string;
  style?: React.CSSProperties; // Allow style prop
}

interface ImageData {
  pictureURL: string;
  picture_caption?: string; // Optional caption property
}

const InventoryPreview: FC<ProfileProps> = ({ id, style }) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [showMoreOpen, setShowMoreOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  async function fetchImages() {
    try {
      const imagesCollectionRef = collection(firestore, 'vendors', id, 'Images'); // Fetching images from the correct path
      const querySnapshot = await getDocs(imagesCollectionRef);
      const fetchedImages: ImageData[] = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.pictureURL) {
          fetchedImages.push({
            pictureURL: data.pictureURL, // Make sure this is the correct field name
            picture_caption: data.picture_caption,
          });
        }
      });

      setImages(fetchedImages);
    } catch (error) {
      console.error('Error fetching images: ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchImages();
  }, [id]);

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setSelectedImage(images[index]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  const handleShowMore = () => {
    setShowMoreOpen(true);
  };

  const handleCloseShowMore = () => {
    setShowMoreOpen(false);
  };

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setSelectedImage(images[currentImageIndex + 1]);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setSelectedImage(images[currentImageIndex - 1]);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={style}>
      <Grid>
        <div>
          {images.length > 0 && (
            <img
              src={`${images[0].pictureURL}?w=161&fit=crop&auto=format`}
              alt={images[0].picture_caption}
              loading="lazy"
              style={{ display: 'block', width: '70vw', height: '30vh', objectFit: 'cover' }} // Full-width banner style
              onClick={() => handleImageClick(0)} // Click the first image to open in modal
            />
          )}
          {images.length > 1 && (
            <Button 
              style={{ position: 'relative', marginTop: '5px', float: 'right', color:'#E4FDE1' }}
              onClick={handleShowMore}
            >
              See Gallery <PhotoSizeSelectActual />
            </Button>
          )}
        </div>
      </Grid>

      {/* Modal for displaying larger image */}
      <Modal open={open} onClose={handleClose}>
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            p: 2,
          }}
        >
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Close />
          </IconButton>
          <IconButton onClick={handlePrevImage} sx={{ position: 'absolute', left: 16 }}>
            <span>❮</span>
          </IconButton>
          <IconButton onClick={handleNextImage} sx={{ position: 'absolute', right: 16 }}>
            <span>❯</span>
          </IconButton>
          {selectedImage && (
            <>
              <img
                src={selectedImage.pictureURL}
                alt="Selected Vendor"
                style={{ maxWidth: '90%', maxHeight: '90%', cursor: 'zoom-in' }} // Zoom-in cursor
              />
              {selectedImage.picture_caption && (
                <Typography
                  variant="h6"
                  sx={{
                    position: 'absolute',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    padding: '0.5em',
                    textAlign: 'center',
                  }}
                >
                  {selectedImage.picture_caption}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Modal>

      {/* Modal for displaying all images */}
      <Modal open={showMoreOpen} onClose={handleCloseShowMore}>
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            p: 2,
            overflowY: 'auto', // Make sure it scrolls when there are many images
            maxHeight: '80vh', // Optional to limit height and allow scrolling
          }}
        >
          <IconButton onClick={handleCloseShowMore} sx={{ position: 'absolute', top: 16, right: 16 }}>
            <Close />
          </IconButton>
          <Grid container spacing={2}>
            {images.map((imageData, index) => (
              <Grid item xs={4} key={index}>
                <img
                  srcSet={`${imageData.pictureURL}?w=161&fit=crop&auto=format&dpr=2 2x`}
                  src={`${imageData.pictureURL}?w=161&fit=crop&auto=format`}
                  alt={`${imageData.picture_caption}`}
                  loading="lazy"
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    cursor: 'pointer', // Pointer cursor for clickable images
                  }}
                  onClick={() => handleImageClick(index)} // Click to view larger image
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default InventoryPreview;
