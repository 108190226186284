import { LocalParking, SmokingRooms } from "@mui/icons-material";
import LocalBarIcon from '@mui/icons-material/LocalBar';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { CircularProgress, Grid, Typography } from "@mui/material";
import { doc, getDoc } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';
import { firestore } from '../../../../firebase';

interface ProfileProps {
  id: string;
  addOns: string[]; // Assuming addOns is an array of strings
  amenities: string[]; // Assuming amenities is an array of strings
}

const KeyInfo: FC<ProfileProps> = ({ id, addOns, amenities }) => {
  const [vendor, setVendor] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [rating, setRating] = useState(0);

  const venueAmenities = [
    {
      img: <RestaurantIcon />,
      title: 'Kitchen',
    },
    {
      img: <LocalBarIcon />,
      title: 'Bar',
    },
    {
      img: <SmokingRooms />,
      title: 'Smoking',
    },
    {
      img: <LocalParking />,
      title: 'Parking',
    }
  ];

  async function setVendorPage() {
    try {
      const docRef = doc(firestore, "Saint Louis", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const result = docSnap.data();
        setVendor(result);

        if (result.vendor_rating && result.vendor_rating > 0) {
          setRating(result.vendor_rating);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching document: ", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setVendorPage();
  }, [id]);

  if (loading) {
    return <CircularProgress />; // Display loading message while fetching data
  }

  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
    {( addOns || amenities ) &&(

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Key Information</h3>
          <hr />
        </Grid>

        <Grid container spacing={2} padding={2}>
        {amenities &&(

          <Grid item xs={12} sm={6}>
            <Typography variant="h4">Amenities</Typography>

            <Grid container spacing={1}>
              {venueAmenities
                .filter(item => amenities.includes(item.title)) // Filter amenities based on props
                .map(item => (
                  <Grid item xs={12} key={item.title}>
                    <Typography>{item.img} {item.title}</Typography>
                  </Grid>
                ))}
            
            </Grid>
           
          </Grid>
 )}
          {addOns &&(
          <Grid item xs={12} sm={6}>
            <h4>Included In Your Booking</h4>
            <Grid container spacing={1}>
              {addOns.map((item, i) => (
                <Grid item xs={12} key={i}>
                  &#9702; {item}
                </Grid>
              ))}
            </Grid>
          </Grid>

          )}
        </Grid>
      </Grid>
       )}

    </Grid>
  );
};

export default KeyInfo;
