import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EFCreateEvent: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    navigate('/createnewevent');
  };

  return (
    <Button
      variant="contained"
      color="info"
      fullWidth
      onClick={handleCreateEvent}
      sx={{marginY: 5,
         marginLeft: 2,
    backgroundColor: "#2596BE",
color: "#fff" }}
    >
      Plan Your Event!
    </Button>
  );
};

export default EFCreateEvent;
