import { Button, CircularProgress, Grid } from "@mui/material";
import { doc, getDoc } from 'firebase/firestore';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { firestore } from '../../../../firebase';
//  TODO: Add conditional to show file or itemized list


interface ProfileProps {
  id: string;
}

const Pricing: FC<ProfileProps> = ({ id }) => {

  const [vendor, setVenue] = useState<any>();
  const [value, setValue] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();



  async function setVendorPage() {
    try{

      const docRef = doc(firestore, "Saint Louis", id);
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
      //  console.log("Document venue data:", docSnap.data());
        const result = docSnap.data();
       // console.log('my result ', result);
        setVenue(result);
        if (vendor?.vendor_rating){
        if (vendor.vendor_rating > 0){
          setRating(vendor.vendor_rating);
          }
          console.log('my rating ', vendor?.vendor_rating);
        }
      } else {
        console.log("No such document!");
      }

    } catch (error) {
      console.error("Error fetching document: ", error);
    } finally {
      setLoading(false); // Set loading to false after fetching is done
    }
   
  }


  useEffect(() => {
		setVendorPage();

	}, [id]);



  
  if (loading) {
    console.log('Profile component loading....');
    return <CircularProgress />; // Display loading message while fetching data
  }
   else{
  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
                  <Button
  variant="contained"
  color="info"
  onClick={() => navigate(`/vendor/${id}/inventory`)}  // Correct function syntax
  sx={{           width: '100%', // Make the button take up full width
  }}
>
  View Vendor&apos;s Inventory
</Button>
    </Grid>

    
 
  );
          }
}

export default Pricing;