import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../firebase";


function LogOut() {
 
    const navigate = useNavigate();

  useEffect(() => {
logout();
    navigate('/');  // Redirect to another page (e.g., dashboard)




   
  }, [navigate]);

  return (
    <>
     
    </>
  );
}

export default LogOut;
