import { useMediaQuery } from "@mui/material"; // For responsive design
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from '../../../firebase'; // Firebase setup

// MUI components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKInput from "../../../components/MKInput";
import MKTypography from "../../../components/MKTypography";

// Eventflow components
import UploadPhoto from "../../../functions/UploadPhoto";

// Firebase imports
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail, updateProfile } from "firebase/auth";

// Images
import bgImage from "../../../assets/images/bg1.jpg";

function SettingsPage() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [newPhotoUrl, setNewPhotoUrl] = useState(''); // State for the new photo URL
  const [oldPassword, setOldPassword] = useState(''); // For changing password
  const [newPassword, setNewPassword] = useState(''); // For changing password
  const [confirmNewPassword, setConfirmNewPassword] = useState(''); // For confirming the new password
  const [loading, setLoading] = useState(false); // To handle loading state
  const [user, setUser] = useState(null); // To store current user data
  const [photoUploaded, setPhotoUploaded] = useState(false); // Flag to track photo upload status
  const [dataLoaded, setDataLoaded] = useState(false); // Flag to track if data has been loaded

  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Check for small screen

  const getCurrentUserData = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUsername(data.username);
        setEmail(data.email);
        setCity(data.city);
        setPhotoUrl(data.photoUrl || ""); // Set the current photo URL if available
        setDataLoaded(true);  // Mark data as loaded
      }
    }
  };

  useEffect(() => {
    getCurrentUserData();
  }, []);

  const handleSaveChanges = async () => {
    setLoading(true);

    const currentUser = auth.currentUser;

    try {
      // Update email if it's changed
      if (email !== currentUser.email) {
        await updateEmail(currentUser, email);
      }

      // Update the display name
      if (username !== currentUser.displayName) {
        await updateProfile(currentUser, {
          displayName: username,
        });
      }

      // Update user data in Firestore
      const userRef = doc(firestore, "users", currentUser.uid);
      await setDoc(userRef, {
        username,
        email,
        city,
        photoUrl: newPhotoUrl || photoUrl, // Set the new photo URL if uploaded
      });

      // Update the photo URL if a new photo was uploaded
      if (newPhotoUrl) {
        await updateProfile(currentUser, {
          photoURL: newPhotoUrl,
        });
      }

      navigate("/profile"); // Redirect to profile page after saving changes
    } catch (error) {
      console.error("Error updating user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("New passwords do not match.");
      return;
    }

    const currentUser = auth.currentUser;

    try {
      // Re-authenticate user with old password
      const credential = EmailAuthProvider.credential(currentUser.email, oldPassword);
      await reauthenticateWithCredential(currentUser, credential);

      // Update password
      await currentUser.updatePassword(newPassword);

      alert("Password updated successfully.");
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Error updating password. Please try again.");
    }
  };

  return (
    <>
      <MKBox
        position="fixed"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      />
      
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          {/* Right Column - Form */}
          <Grid item xs={12} md={5}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={5}>
                  Settings
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {/* Settings Form */}
                <MKBox component="form" role="form">
                  {dataLoaded ? (
                    <>
                      <MKBox mb={2}>
                        <MKInput
                          type="text"
                          label="Username"
                          fullWidth
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </MKBox>

                      <MKBox mb={2}>
                        <MKInput
                          type="email"
                          label="Email"
                          fullWidth
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </MKBox>

                      <UploadPhoto
                        id={auth.currentUser?.uid}
                        source="users"
                        onUploadComplete={(url) => {
                          setNewPhotoUrl(url); // Update the photo URL when upload is complete
                          setPhotoUploaded(true); // Mark photo as uploaded
                        }}
                      />

                      <MKBox mt={3}>
                        <MKTypography variant="h6" fontWeight="medium" color="textPrimary">
                          Change Password
                        </MKTypography>
                      </MKBox>

                      <MKBox mb={2}>
                        <MKInput
                          type="password"
                          label="Old Password"
                          fullWidth
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </MKBox>

                      <MKBox mb={2}>
                        <MKInput
                          type="password"
                          label="New Password"
                          fullWidth
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </MKBox>

                      <MKBox mb={2}>
                        <MKInput
                          type="password"
                          label="Confirm New Password"
                          fullWidth
                          value={confirmNewPassword}
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                      </MKBox>

                      <MKBox mt={4} mb={1}>
                        <MKButton
                          variant="gradient"
                          color="info"
                          fullWidth
                          onClick={handleSaveChanges}
                          disabled={loading}
                        >
                          Save Changes
                        </MKButton>
                      </MKBox>

                      <MKBox mt={2}>
                        <MKButton
                          variant="outlined"
                          color="warning"
                          fullWidth
                          onClick={handleChangePassword}
                          disabled={loading}
                        >
                          Change Password
                        </MKButton>
                      </MKBox>
                    </>
                  ) : (
                    <MKBox width="100%" textAlign="center" py={6}>
                      <MKTypography variant="h5">Loading...</MKTypography>
                    </MKBox>
                  )}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>

     
    </>
  );
}

export default SettingsPage;
