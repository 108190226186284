// SimilarVendorsCarousel.tsx
import { Grid } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-slick'; // Import react-slick here
import EFCard from '../../components/EFCard';
import { firestore } from '../../firebase';

interface SimilarVendorsCarouselProps {
  vendorType: string;
  city: string;
}

const SimilarVendorsCarousel: React.FC<SimilarVendorsCarouselProps> = ({ vendorType, city }) => {
  const [similarVendors, setSimilarVendors] = useState<any[]>([]);

  // Fetch similar vendors based on vendor type and city
  const getSimilarVendors = async () => {
    const q = query(
      collection(firestore, 'vendors'),
      where('vendor_type', '==', vendorType),
      where('vendor_majorCity', '==', decodeURIComponent(city))
    );
    const querySnapshot = await getDocs(q);
    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSimilarVendors(entries);
  };

  useEffect(() => {
    if (vendorType && city) {
      getSimilarVendors();
    }
  }, [vendorType, city]);

  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
    <Grid style={{ width: '100%'}}>
    <h4>Similar Vendors</h4>

    <Carousel
  dots={true}
  infinite={true}
  slidesToShow={1}
  slidesToScroll={1}
  speed={500}
  autoplay={true}
  autoplaySpeed={3000}
>
  {similarVendors.length > 0 ? (
    similarVendors.map((vendor) => (
      <div key={vendor.id}>
        <EFCard posts={[vendor]} />
      </div>
    ))
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
      {/* Text Block */}
      <div style={{ maxWidth: '500px', marginRight: '30px' }}>
        <h2 style={{ fontSize: '24px', color: '#2c3e50' }}>
          Keep Planning, Keep Growing with Eventflow!
        </h2>
        <p style={{ fontSize: '18px', color: '#7f8c8d', marginTop: '10px' }}>
          Whether you&apos;re hosting a small gathering or a grand event, Eventflow is here to help you make it a success.
          <br />
          Connect with amazing vendors, manage your tasks, and create unforgettable experiences—all in one place!
        </p>
        <button 
          onClick={() => window.location.href = '/explore-vendors'} 
          style={{ 
            padding: '12px 30px', 
            marginTop: '20px', 
            backgroundColor: '#3498db', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: 'pointer', 
            fontSize: '16px' 
          }}>
          Explore Vendors Now
        </button>
        <p style={{ fontSize: '14px', color: '#bdc3c7', marginTop: '10px' }}>
          Discover the best vendors in your area and take your event planning to the next level!
        </p>
      </div>

      {/* Carousel Block (Empty placeholder) */}
      <div style={{ flex: 1, height: '300px', backgroundColor: '#ecf0f1', borderRadius: '10px' }}>
        <p style={{ textAlign: 'center', color: '#bdc3c7', lineHeight: '300px' }}>
          No vendors available yet. Explore vendors to start planning your event!
        </p>
      </div>
    </div>
  )}
</Carousel>


  </Grid>
  </Grid>
  
  );
};

export default SimilarVendorsCarousel;
