import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import bgImage from "../../assets/images/bg1.jpg";
import MKBox from "../../components/MKBox";
import ViewVendors from "./ViewVendors";

// Helper function to get the nearest city from geolocation
const getCityFromCoordinates = async (lat, lon) => {
  try {
    const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`);
    const data = await response.json();
    return data.locality || 'Unknown City'; // Returns the city name
  } catch (error) {
    console.error("Error fetching city from coordinates:", error);
    return 'Unknown City'; // Fallback to a default city if the API fails
  }
};

function Vendor() {
  const [searchQuery, setSearchQuery] = useState('');
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userCity, setUserCity] = useState('');
  const { city } = useParams();  // Extract the city parameter from the URL
  const navigate = useNavigate(); // To navigate if city is not found

  // Search function to get vendors
  const handleSearch = async () => {
    setLoading(true);
    const db = getFirestore();

    try {
      const vendorsCollection = collection(db, userCity);  // Use the user's city or default
      const q = query(vendorsCollection, where('name', '>=', searchQuery), where('name', '<=', searchQuery + '\uf8ff'));
      const querySnapshot = await getDocs(q);

      const vendorList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setVendors(vendorList);
    } catch (error) {
      console.error('Error searching vendors: ', error);
    } finally {
      setLoading(false);
    }
  };

  // If no city is provided, fetch user's location
  useEffect(() => {
    if (!city) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          const cityName = await getCityFromCoordinates(latitude, longitude);
          setUserCity(cityName); // Set the city from geolocation
          navigate(`/vendors/${cityName}`); // Redirect to vendor page for that city
        }, (error) => {
          console.error('Geolocation error: ', error);
          setUserCity('Unknown City');
          navigate('/welcome'); // Redirect to welcome page if location is not found
        });
      } else {
        setUserCity('Unknown City');
        navigate('/welcome'); // Fallback to welcome page if geolocation is unavailable
      }
    } else {
      setUserCity(city); // Use the city from the URL if available
    }
  }, [city, navigate]);

  // Use the search query to filter vendors
  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      setVendors([]);
    }
  }, [searchQuery]);

  // If no city has been set yet (loading state)
  if (!userCity) {
    return <CircularProgress />;
  }

  return (
    <>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
        </MKBox>

        <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Card
              sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                width: '90%',
              }}
            >
              <ViewVendors vendors={vendors} />
            </Card>
          </Grid>
        </MKBox>
      </MKBox>


    </>
  );
}

export default Vendor;
