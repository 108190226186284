import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EFCart: React.FC = () => {
  const navigate = useNavigate();

  const handleGoToCart = () => {
    navigate('/cart');
  };

  return (
    <Button
      variant="contained"
      color="info"
      onClick={handleGoToCart}
      sx={{marginY: 5,
         marginLeft: 2,
    backgroundColor: "#2596BE",
color: "#fff" }}
    >
      Cart
    </Button>
  );
};

export default EFCart;
