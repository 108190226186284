
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Eventflow.app React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://gomag.com/wp-content/uploads/2019/03/iStock-1012989218.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6, height: '57vh' } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            Eventflow Checklists
          </MKTypography>
          <MKTypography variant="h2" color="white" mb={1}>
          Effortless Party Planning with EventFlow
          </MKTypography>
          <MKTypography variant="body1" color="white" fontSize={.9+'em'} opacity={0.8} mb={2}>
          Planning a birthday party should be fun and exciting, not stressful. EventFlow&apos;s birthday planning checklists simplify
          the process, helping you manage every detail effortlessly. From budgeting and guest lists to finding the best caterers 
          and entertainers, our checklists ensure nothing is overlooked. Focus on enjoying the celebration while we take care of the organization.
          </MKTypography>
          <MKTypography
            component="a"
            href="#"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Join our mailing list <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
