import { Button, Card, Grid, TextField } from '@mui/material';
import { addDoc, collection } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to get vendorId from URL
import MKBox from '../../../../components/MKBox';
import MKTypography from '../../../../components/MKTypography';
import { firestore } from '../../../../firebase'; // Your Firebase setup

function AddInventoryPage() {
  const { vendorId } = useParams(); // Get vendorId from URL params
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const itemRef = collection(firestore, 'vendors', vendorId, 'Inventory');
      await addDoc(itemRef, {
        name,
        price: parseFloat(price),
        description,
        imageUrl,
      });

      setLoading(false);
      navigate(`/vendor/${vendorId}/inventory`); // Redirect back to inventory page
    } catch (error) {
      console.error("Error adding item:", error);
      setLoading(false);
    }
  };

  return (
    <MKBox px={2} py={4}>
      <MKTypography variant="h4">Add New Item</MKTypography>
      <Card sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Item Name"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Price"
                fullWidth
                type="number"
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                multiline
                rows={4}
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Image URL"
                fullWidth
                required
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={loading}
              >
                {loading ? "Saving..." : "Add Item"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </MKBox>
  );
}

export default AddInventoryPage;
