
/** 
  All of the routes for the Eventflow.app React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons

// Pages
import AboutUs from "././layouts/pages/landing-pages/about-us";
import ContactUs from "././layouts/pages/landing-pages/contact-us";
import MyEvents from "./pages/Users/Events/MyEvents";

// Sections
import CreateEvent from "./pages/Users/Events/CreateEvent";
import RegisterUser from "./pages/Users/Register";
import UserSettings from "./pages/Users/Settings";
import InventoryPage from "./pages/Vendors/Inventory";
import VendorRegistration from "./pages/Vendors/Registration/VendorRegistration";

const routes = [
  {
    name: "Explore",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "",
        collapse: [
          {
            name: "sign up",
            route: '/welcometoeventflow',
            component: <RegisterUser />,
          },
          {
            name: "contact us",
            route: "/pages/landing-pages/contact-us",
            component: <ContactUs />,
          },
      /*     {
            name: "author",
            route: "/pages/landing-pages/author",
            component: <Author />,
          }, */
          //*/
        
        ],
      },
/*       {
        name: "for vendors",
        collapse: [
      
          {
            name: "Become a Vendor",
            route: "/pages/landing-pages/about-us",
            component: <AboutUs />,
          },
        ],
      }, */
    ],
  },
  {
    name: "For Planners",
    icon: <Icon>view_day</Icon>,
    collapse: [
    
      {
        name: "create event",
        route: "/createnewevent",
        component: <CreateEvent />,
      }, 
      {
        name: "my events",
        route: "/myevents",
        component: <MyEvents />,
      },       
    
     
      {
        name: "account",
        route: '/accountsettings',
        component: <UserSettings />,
      },


    ],
  },
  {
    name: "For Vendors",
    icon: <Icon>article</Icon>,
    collapse: [
      
   
      {
        name: "Become a Vendor",
        route: "/become-a-vendor",
        component: <VendorRegistration/>,
      }, 
       {
        name: "Inventory",
        route: `/vendor/:vendorId/inventory`,
        component: <InventoryPage/>,
      },

      


      {
        name: "Account",
        route: "/vendorsettings",
        component: <AboutUs />,
      },
    
    ],
  },
];



export default routes;
