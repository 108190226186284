import { Button, Card, CardContent, CardMedia, Grid, Snackbar } from '@mui/material';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // For accessing vendorId from URL and navigation
import { ToastContainer } from 'react-toastify';
import MKBox from "../../../components/MKBox"; // Styled component
import MKTypography from "../../../components/MKTypography"; // For title/headers
import { auth, firestore } from '../../../firebase'; // Firebase setup
import BaseLayout from '../../../layouts/sections/components/BaseLayout';
import { useCart } from '../../Users/Cart/CartContext';

const InventoryPage = () => {
  const { vendorId } = useParams(); // Get vendorId from the URL
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVendor, setIsVendor] = useState(false); // Track if the logged-in user is the vendor
  const navigate = useNavigate(); // Navigation hook
  const { addToCart } = useCart(); // Use the addToCart function from CartContext
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchInventoryAndCheckOwnership = async () => {
      try {
        // Fetch the vendor document to check if the current user is the vendor
        const vendorDocRef = doc(firestore, 'vendors', vendorId);
        const vendorDoc = await getDoc(vendorDocRef);
        const vendorData = vendorDoc.data();

        // Check if the logged-in user is the vendor
        const currentUser = auth.currentUser;
        if (currentUser && currentUser.uid === vendorData.vendor_own) {
          setIsVendor(true); // The logged-in user is the vendor
        }

        // Fetch inventory items
        const inventoryCollectionRef = collection(firestore, 'vendors', vendorId, 'Inventory');
        const inventorySnapshot = await getDocs(inventoryCollectionRef);
        const inventoryList = inventorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInventory(inventoryList);
      } catch (error) {
        console.error('Error fetching inventory:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryAndCheckOwnership();
  }, [vendorId]); // Dependency on vendorId so it refetches when the vendorId changes

  // Handle Add to Cart
  const handleAddToCart = (item) => {
    setSuccessMessage(`Item ${item.name} added to cart`);
    addToCart(item); // Call addToCart from the CartContext
  };

  // Handle Edit Item (Navigate to Edit Page)
  const handleEditItem = (itemId) => {
    navigate(`/vendor/${vendorId}/edit-inventory/${itemId}`);
  };

  // Navigate to Add Inventory Page
  const handleAddNewItem = () => {
    navigate(`/vendor/${vendorId}/add-inventory`);
  };

  return (
    <BaseLayout
      title="Inventory"
      breadcrumb={[
        { label: "Vendor Page", route: `/vendor/${vendorId}` },
      ]}
    >
      <Grid>
        <MKBox>
          {/* Show "Add New Item" button if the logged-in user is the vendor */}
          {isVendor && (
            <Button
              variant="contained"
              onClick={handleAddNewItem}
              sx={{
                marginBottom: 3,
                backgroundColor: '#2596BE',
                color: '#fff',
              }}
            >
              Add New Item
            </Button>
          )}

          {loading ? (
            <p>Loading...</p>
          ) : (
            <Grid container spacing={3} justifyContent="center">
              {/* Display Inventory Items as Cards */}
              {inventory.map((item) => (
                <Grid item key={item.id} xs={12} sm={6} md={4}>
                  <Card>
                    {/* Image display */}
                    {item.imageUrl && (
                      <CardMedia
                        component="img"
                        height="auto" // Auto adjust height
                        image={item.imageUrl}
                        alt={item.name}
                        sx={{
                          objectFit: 'fit',
                          width: '90%', // Make image fill the container width
                          maxHeight: '250px', // Limit the height for uniformity
                        }}
                      />
                    )}

                    <CardContent>
                      <MKTypography variant="h6" gutterBottom>
                        {item.name}
                      </MKTypography>
                      <MKTypography variant="body2" color="textSecondary">
                        {item.description}
                      </MKTypography>
                      <MKTypography variant="h6" color="primary" mt={2}>
                        ${item.price}
                      </MKTypography>

                      {/* Render different buttons based on the user's role */}
                      {isVendor ? (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleEditItem(item.id)}
                          fullWidth
                          sx={{
                            marginTop: 2,
                            color: '#2596BE',
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleAddToCart(item)} // Use handleAddToCart to add item
                          fullWidth
                          sx={{ marginTop: 2 }}
                        >
                          Add to Cart
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          <Snackbar
            open={!!errorMessage}
            autoHideDuration={8000}
            onClose={() => setErrorMessage('')}
            message={errorMessage}
          />
          <Snackbar
            open={!!successMessage}
            autoHideDuration={8000}
            onClose={() => setSuccessMessage('')}
            message={successMessage}
          />
          <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />
        </MKBox>
      </Grid>
    </BaseLayout>
  );
};

export default InventoryPage;
