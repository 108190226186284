// BookingForm.tsx
import { Box, Button, Container, Grid, Snackbar, TextField } from '@mui/material';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase';
interface BookingFormProps {
  vendorId: string;
  vendorEmail: string; // Vendor's email for sending notifications
}

const BookingForm: React.FC<BookingFormProps> = ({ vendorId, vendorEmail }) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [capacity, setCapacity] = useState('');
  const [specialRequests, setSpecialRequests] = useState('');
  const [isTimeAvailable, setIsTimeAvailable] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isTimeAvailable) {
      setErrorMessage('Selected time is not available. Please choose a different time.');
      return;
    }
    try {
      const bookingRef = doc(firestore, `vendors/${vendorId}/Booking`, `${Date.now()}`);
      await setDoc(bookingRef, {
        booking_name: name,
        booking_date: date,
        booking_time: time,
        booking_specialRequests: specialRequests,
        vendorEmail,
        requestor_email: auth.currentUser.email, 
        requestor_id: auth.currentUser.uid
      });



      alert('Booking successful! An email has been sent to the vendor.'); // Notify user
      setSuccessMessage('Booking successful! An email has been sent to the vendor.');

      // Reset form fields
      setName('');
      setDate('');
      setTime('');
      setCapacity('');
      setSpecialRequests('');
    } catch (error) {
      console.error('Error booking:', error);
      setErrorMessage('There was an error processing your booking. Please try again.');
    }

    const eventDocRef = doc(firestore, 'vendors', vendorId);
  try{
     await setDoc(eventDocRef, {
       new_bookings: 1,
     }, { merge: true });
     
   } catch (error) {
     setErrorMessage(`Error notifying vendor.`);
   }

  };

  const checkTimeAvailability = async () => {
    if (date && time) {
      const bookingsRef = collection(firestore, `vendors/${vendorId}/Booking`);
      const querySnapshot = await getDocs(bookingsRef);
      const bookedTimes = querySnapshot.docs.map(doc => doc.data().time);

      setIsTimeAvailable(!bookedTimes.includes(time));
    }
  };

  useEffect(() => {
    checkTimeAvailability();
    console.log('auth status: ', auth.currentUser);
  }, [date, time]);

  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
      
    <Container>
    <h3>Booking Inquiry</h3>
    
<form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label=""
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <TextField
          label="Time"
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          required
        />
             <TextField
          label="Capacity"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          required
        />
        <TextField
          label="Special Requests"
          value={specialRequests}
          onChange={(e) => setSpecialRequests(e.target.value)}
          multiline
          rows={4}
        />
        { (!auth.currentUser?.isAnonymous || !(auth.currentUser == null)) && (
          <div>
 <Button type="submit" variant="contained" color="info" disabled={!isTimeAvailable}>
          Book Now
        </Button>
        {!isTimeAvailable && <p style={{ color: 'red' }}>Selected time is not available. Please choose a different time.</p>}
        
          </div>

        )}

      </Box>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
    </form>
    </Container>
    </Grid>
    
  );
};

export default BookingForm;
