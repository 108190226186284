// ScrollingBanner.tsx
import { Box, Typography } from '@mui/material';
import React from 'react';

interface ScrollingBannerProps {
  text: string; // The text to scroll
  speed: number; // The speed of the scrolling animation (higher is faster)
}

const EFBanner: React.FC<ScrollingBannerProps> = ({ text, speed }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '5vh',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#2596BE', //  EF Blue
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: '#F2F2F2',
          position: 'absolute',
          animation: `scroll ${speed}s linear infinite`,
        }}
      >
        {text}
      </Typography>

      <style>
        {`
          @keyframes scroll {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default EFBanner;
