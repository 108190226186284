import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

interface TierOption {
  name: string;
  price: string;
  description: string;
  features: string[];
}

const tiers: TierOption[] = [
  {
    name: 'Free',
    price: 'Free',
    description: 'No links allowed in description.',
    features: [
      'Name',
      'Description (no links)',
      'Avi',
      'Type',
    ],
  },
  {
    name: 'Lite',
    price: '$15/mo',
    description: 'Add-ons available.',
    features: [
      'Listing',
      'Contact information',
      'Up to 5 photos',
      'Add links',
    ],
  },
  {
    name: 'Standard',
    price: '$50',
    description: 'Unlimited photos, booking without chat unless booked.',
    features: [
      'Unlimited photos',
      'Booking (no chat unless booked)',
      'Respond to reviews',
    ],
  },
];

interface EFTiersProps {
  onSelectTier: (tier: string) => void;
}

const EFTiers: React.FC<EFTiersProps> = ({ onSelectTier }) => {
  const [selectedTier, setSelectedTier] = useState<string | null>(null);

  const handleSelect = (tier: string) => {
    setSelectedTier(tier);
    onSelectTier(tier);
  };

  return (
    <Grid container spacing={3}>
      {tiers.map((tier) => (
        <Grid item xs={12} sm={4} key={tier.name}>
          <Card variant="outlined" style={{ border: selectedTier === tier.name ? '2px solid #3f51b5' : 'none' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {tier.name}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                {tier.price}
              </Typography>
              <Typography variant="body2">
                {tier.description}
              </Typography>
              <ul>
                {tier.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                variant="contained" 
                color="primary" 
                onClick={() => handleSelect(tier.name)}
              >
                Select
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default EFTiers;
