import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { onAuthStateChanged } from "firebase/auth"; // Import onAuthStateChanged
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import theme from "./assets/theme";
import './ef-styles.css';
import DefaultNavbar from "./examples/Navbars/DefaultNavbar";
import { auth, logout } from "./firebase"; // Import auth from firebase setup
import Presentation from "./layouts/pages/presentation";
import LogOut from "./pages/logout";
import CartPage from "./pages/Users/Cart";
import { CartProvider } from "./pages/Users/Cart/CartContext";
import EventDetails from "./pages/Users/Events/EventDetails";
import Vendor from "./pages/Vendors";
import InventoryPage from "./pages/Vendors/Inventory";
import AddInventoryPage from "./pages/Vendors/Inventory/AddInventory";
import VendorPage from "./pages/Vendors/VendorPage";
import routes from "./routes";

export default function App() {
  const { pathname } = useLocation();
  const [status, setStatus] = useState(false);
  const [globalDownloadURL, setglobalDownloadURL] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    // Firebase Auth State Listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setStatus(true);
        console.log("User signed in:", user.uid);
      } else {
        setStatus(false);
        console.log("No user signed in");
      }
    });

    // Cleanup subscription on component unmount
    return () => unsubscribe();
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const handleLogout = () => {
    logout();
    setRedirectToHome(true); // Redirect to the homepage after logout
  };

  if (redirectToHome) {
    return <Navigate to="/" />; // Redirect to the homepage
  }

  return (
    <CartProvider> {/* Wrap the app in CartProvider to provide cart context */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DefaultNavbar
        className="navbar"
          routes={routes}
          action={{
            type: "internal",
            route: status ? "/log-out" : "/sign-in",
            label: status ? "Log Out" : "Sign In",
            color: "accent",
          }}
          
          dark
        />
        
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          {/* Main content area */}
          <div style={{ flex: 1 }}>
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Presentation />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/log-out" element={<LogOut />} />
              <Route path="/vendor/:id" element={<VendorPage />} />
              <Route path="/vendors/:city" element={<Vendor />} />
              <Route path="/event-details/:eventId" element={<EventDetails />} />
              <Route path="/vendor/:vendorId/inventory" element={<InventoryPage />} />
              <Route path="/vendor/:vendorId/add-inventory" element={<AddInventoryPage />} />
              <Route path="/cart" element={<CartPage />} /> {/* Add CartPage route */}
            </Routes>
          </div>

        </div>
      </ThemeProvider>
    </CartProvider>
  );
}
