import { Box, Grid, Typography } from "@mui/material";
import { FC, useState } from 'react';
//  TODO: Add conditional to show file or itemized list


interface ProfileProps {
  id: string;
  address: any;
  phone: string;
  name: string;
}



const ContactVendor: FC<ProfileProps> = ({ id, address, phone, name }) => {

  const [vendor, setVenue] = useState<any>();
  const [value, setValue] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  const [loading, setLoading] = useState(true);



  
  return (
    <Grid container spacing={1} padding={4} justifyContent="left" alignItems="center" height="100%">
    <Box>
      <h3>Contact</h3>
      <hr></hr>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
      <Typography id='contactinfo'>
        {name}<br></br>
  {address} <br></br>
        <a className='button' href="#">{phone}</a>
      </Typography>
    </Grid>
      </Box>
    </Grid>
 
  );
          }


export default ContactVendor;