import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, CardContent, CardMedia, Fab, IconButton, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Use useNavigate instead of useHistory
import { auth, firestore } from "../../../../firebase";
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";

function EventDetails() {
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();  // Use useNavigate instead of useHistory
  const [event, setEvent] = useState(null);  // State for event data
  const [loading, setLoading] = useState(true);  // State for loading status
  const [error, setError] = useState("");  // State for error messages
  const [vendorList, setVendorList] = useState([]); // List of vendors with full details
  const [guestList, setGuestList] = useState([]); // List of guest names
  const [newGuestName, setNewGuestName] = useState("");  // State for new guest name input
  const [editingGuestIndex, setEditingGuestIndex] = useState(null);  // Index of guest being edited

  const businessTypeDropdown = [
    'Catering', 'DJs and Promoters', 'Event Decorator', 'Equipment Rental', 'Experience', 'Event Planner', 'Photography and Videography', 'Security', 'Transportation', 'Venue'
  ];

  // Fetch event data
  async function setEventPage() {
    const docRef = doc(firestore, "users", auth.currentUser.uid, "Events", eventId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const result = docSnap.data();
      setEvent(result);
      setGuestList(result.guests || []);  // Set guest list data
      setLoading(false);
    } else {
      console.log("No such document!");
      setLoading(false);
      setError("Event not found.");
    }
  }

  // Fetch vendors from the "Vendors" sub-collection
  const fetchVendors = async () => {
    if (!eventId) return; // Ensure eventId is available

    const vendorCollectionRef = collection(firestore, "users", auth.currentUser.uid, "Events", eventId, "Vendors");
    const vendorSnapshot = await getDocs(vendorCollectionRef);

    // Get vendor IDs from the event's Vendors sub-collection
    const vendorIds = vendorSnapshot.docs.map(doc => doc.data().vendor_id);

    if (vendorIds.length === 0) return; // If no vendors, return early

    // Fetch full vendor details from the 'vendors' collection
    const vendorDetails = [];
    for (const vendorId of vendorIds) {
      const vendorDocRef = doc(firestore, "vendors", vendorId);  // Assuming "vendors" is a top-level collection
      const vendorDocSnap = await getDoc(vendorDocRef);

      if (vendorDocSnap.exists()) {
        vendorDetails.push({ id: vendorDocSnap.id, ...vendorDocSnap.data() });
      }
    }

    // Set the full vendor list with details
    setVendorList(vendorDetails);
  };

  useEffect(() => {
    setEventPage();
  }, [eventId]);

  useEffect(() => {
    if (event) {
      fetchVendors();  // Fetch vendors after the event data is loaded
    }
  }, [event]);

  // Add a new guest to the list
  const handleAddGuest = async () => {
    if (newGuestName.trim() === "") {
      alert("Please enter a valid guest name.");
      return;
    }

    const updatedGuestList = [...guestList, newGuestName];

    // Update the Firestore event document with the new guest list
    try {
      await updateDoc(doc(firestore, "users", auth.currentUser?.uid, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
      setNewGuestName("");  // Clear the input field
    } catch (error) {
      console.error("Error adding guest: ", error);
    }
  };

  const handleGoToInventory = () => {
    navigate(`/vendor/${vendorId}/inventory`);
  };

  // Delete a guest from the list
  const handleDeleteGuest = async (guestName) => {
    const updatedGuestList = guestList.filter((guest) => guest !== guestName);

    // Update the Firestore event document by removing the deleted guest
    try {
      await updateDoc(doc(firestore, "users", auth.currentUser?.uid, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
    } catch (error) {
      console.error("Error deleting guest: ", error);
    }
  };

  // Edit a guest name
  const handleEditGuest = (index) => {
    setEditingGuestIndex(index);
    setNewGuestName(guestList[index]);
  };

  // Save the edited guest name
  const handleSaveGuestEdit = async () => {
    if (newGuestName.trim() === "") {
      alert("Please enter a valid guest name.");
      return;
    }

    const updatedGuestList = guestList.map((guest, index) => (index === editingGuestIndex ? newGuestName : guest));

    try {
      await updateDoc(doc(firestore, "users", auth.currentUser?.uid, "Events", eventId), {
        guests: updatedGuestList,
      });

      setGuestList(updatedGuestList);  // Update local state
      setNewGuestName("");  // Clear the input field
      setEditingGuestIndex(null);  // Clear the editing index
    } catch (error) {
      console.error("Error editing guest: ", error);
    }
  };

  // Track guest capacity
  const remainingCapacity = event ? event.event_capacity - guestList.length : 0;

  // Group vendors by their type
  const groupedVendors = businessTypeDropdown.reduce((acc, type) => {
    acc[type] = vendorList.filter(vendor => vendor.vendor_type === type);
    return acc;
  }, {});

  // Show loading indicator or error message if data is not fetched
  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>{error}</Typography>;

  return (
    <BaseLayout
      title={event.event_name}
      breadcrumb={[
        { label: "Events", route: "/myevents" },
        { label: "Event Details", route: `/event-details/${eventId}` },
      ]}
    >
      <Card sx={{ display: 'flex', flexDirection: 'column', marginBottom: 3 }}>
        {/* Event Image */}
        {event.event_image ? (
          <CardMedia
            component="img"
            alt={event.event_name}
            height="300"
            image={event.event_image}
            sx={{ objectFit: 'fit',
          width: '95%' }}
          />
        ) : (
          <Box
            sx={{
              height: '300px',
              backgroundColor: '#f5f5f5',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#aaa',
            }}
          >
            No Image Available
          </Box>
        )}

        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            {event.event_name}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            <strong>Description: </strong>{event.event_description}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            <strong>Date: </strong>{new Date(event.event_date).toLocaleDateString()}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            <strong>City: </strong>{event.event_city}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            <strong>Capacity: </strong>{event.event_capacity}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            <strong>Budget: </strong>${event.event_budget}
          </Typography>
        </CardContent>
      </Card>

      {/* Guest List Section */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" component="div" gutterBottom>
          Guest List
        </Typography>

        {/* Display the remaining capacity */}
        <Typography variant="body1" color="textSecondary">
          <strong>Remaining Capacity: </strong>{remainingCapacity} / {event.event_capacity}
        </Typography>

        {/* Add Guest Input */}
        {remainingCapacity > 0 && (
          <Box sx={{ marginTop: 2 }}>
            <TextField
              label="Guest Name"
              value={newGuestName}
              onChange={(e) => setNewGuestName(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={editingGuestIndex !== null ? handleSaveGuestEdit : handleAddGuest}
              sx={{ marginTop: 2 }}
            >
              {editingGuestIndex !== null ? "Save Changes" : "Add Guest"}
            </Button>
          </Box>
        )}

        {/* Display guest list */}
        <List sx={{ marginTop: 2 }}>
          {guestList.length > 0 ? (
            guestList.map((guest, index) => (
              <ListItem key={index}>
                <ListItemText primary={guest} />
                <IconButton onClick={() => handleEditGuest(index)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteGuest(guest)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))
          ) : (
            <Typography>No guests added yet.</Typography>
          )}
        </List>
      </Box>

      {/* Vendor List Section */}
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h6" component="div" gutterBottom>
          Vendor List
        </Typography>

        {/* Loop through businessTypeDropdown to display vendors by type */}
        {businessTypeDropdown.map((type) => (
          <Box key={type} sx={{ marginTop: 3 }}>
            <Typography variant="h6" component="div" gutterBottom>
              {type}
            </Typography>
            <List sx={{ marginTop: 2 }}>
              {groupedVendors[type]?.length > 0 ? (
                groupedVendors[type].map((vendor, index) => (
                  <ListItem key={vendor.id}>
                    {vendor.vendor_pictureUrl ? (
                      <CardMedia
                        component="img"
                        alt={vendor.vendor_name}
                        image={vendor.vendor_pictureUrl}
                        sx={{
                          width: 90,
                          height: 100,
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          width: 90,
                          height: 100,
                          backgroundColor: '#f5f5f5',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '8px',
                          color: '#aaa',
                        }}
                      >
                        No Image
                      </Box>
                    )}
                    <ListItemText primary={vendor.vendor_name} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {/* Visit Page Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/vendor/${vendor.vendor_id}`)}
                        sx={{
                          color: '#fff',
                          width: '100%', // Make the button take up full width
                        }}
                      >
                        Visit Page
                      </Button>

                      {/* Link/Button to Vendor's Inventory Page */}
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => navigate(`/vendor/${vendor.vendor_id}/inventory`)}  // Correct function syntax
                        sx={{ width: '100%' }}
                      >
                        View Vendor&apos;s Inventory
                      </Button>

                      {/* Delete Vendor Button */}
                      <Button
                        onClick={() => handleDeleteVendor(vendor.vendor_id)}
                        variant="contained"
                        sx={{
                          width: '100%', // Full width button
                          backgroundColor: 'red',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#d32f2f',
                          },
                        }}
                      >
                        Delete <DeleteIcon sx={{ marginLeft: 1 }} />
                      </Button>
                    </Box>
                  </ListItem>
                ))
              ) : (
                <Typography>No vendors for this type yet.</Typography>
              )}
            </List>
          </Box>
        ))}
      </Box>

      {/* Floating button to browse vendors for this event */}
      <Fab
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          width: 'auto',
          height: 48,
          padding: '0 16px',
          borderRadius: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => navigate(`/vendors/${event.event_city}`)}
      >
        Browse Vendors for {event.event_name}
      </Fab>
    </BaseLayout>
  );
}

export default EventDetails;
