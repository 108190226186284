import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Snackbar } from '@mui/material';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, firestore } from '../firebase';


interface AddToEventProps {
  vendorId: string;
  vendorType: string;
}

const EFAddToEvent: React.FC<AddToEventProps> = ({ vendorId, vendorType }) => {
  const [open, setOpen] = useState(false); // State to handle modal visibility
  const [events, setEvents] = useState<any[]>([]); // State to store fetched events
  const [loading, setLoading] = useState(false); // State for loading indicator

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch events when the modal opens
  const fetchEvents = async () => {
    try {
      setLoading(true);
      const eventsCollection = collection(firestore, 'users', auth.currentUser?.uid, 'Events');
      const querySnapshot = await getDocs(eventsCollection);
      const eventsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events: ", error);
      setLoading(false);
    }
  };

  const handleAddToEvent = async (eventId: string) => {
    try {
     const eventDocRef = doc(firestore, 'users', auth.currentUser?.uid, 'Events', eventId, 'Vendors', vendorId);
     // const eventDocRef = doc(firestore, 'users', auth.currentUser?.uid, 'Events', eventId);
   
      await setDoc(eventDocRef, {
        vendor_id: vendorId,
        vendor_type: vendorType,
      }, { merge: true });
      //  Remove at release
     
      
      setSuccessMessage('Vendor added to the event successfully!');
      setOpen(false); // Close the modal after adding
    } catch (error) {
      setErrorMessage(`Error adding vendor to event: ${error.message}`);
    }
  };

  const handleOpen = () => {
    setOpen(true);
    fetchEvents();
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <button
        onClick={handleOpen}
        className="profile-icons"
        style={{
          border: 'none',
          background: 'none',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        <AddIcon />
      </button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Select an Event</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <List>
              {events.length > 0 ? (
                events.map(event => (
                  <ListItem button key={event.event_id} onClick={() => handleAddToEvent(event.event_id)}>
                    <ListItemText primary={`${event.event_name} ( ${event.event_date} )`|| `${event.event_date}`} />
                  </ListItem>
                ))
              ) : (
                <p>No events available. Create one to add vendors.</p>
              )}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>


      </Dialog>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={8000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <Snackbar
        open={!!successMessage}
        autoHideDuration={8000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
      />
      <ToastContainer position="bottom-center" autoClose={8000} hideProgressBar />

    </>
  );
};

export default EFAddToEvent;
