import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MKButton from '../../../../components/MKButton';
import { auth, firestore } from "../../../../firebase";

import EFCart from '../../../../components/EFCart';
import BaseLayout from "../../../../layouts/sections/components/BaseLayout";

function MyEvents() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); 

  // Fetch events for the current user
  async function getEvents() {
    const q = query(collection(firestore, "users", auth.currentUser.uid, "Events"));
    const querySnapshot = await getDocs(q);
    try {
      const entries = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(entries);
    } catch {
      console.log("Error fetching documents");
    }
  }

  useEffect(() => {
    if (auth.currentUser && !auth.currentUser?.isAnonymous) {
      getEvents();
    } else {
      alert('You must sign in to create an event.');
      navigate('/sign-in');  // Redirect to login or another page
    }
  }, [auth.currentUser]);

  return (
    <BaseLayout
      title="Events"
      breadcrumb={[
        { label: "Events", route: "/myevents" },
      ]}
    >
      {events.length > 0 && (
        <Grid container spacing={3}>
          {events.map(event => (
            <Grid item xs={12} sm={6} md={4} key={event.event_id}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  '&:hover': {
                    boxShadow: 3,
                    transform: 'scale(1.05)',
                    transition: 'transform 0.3s ease-in-out',
                  },
                }}
                
                onClick={() => {
                  console.log(`Navigating to /event-details/${event.event_id}`);
                  navigate(`/event-details/${event.event_id}`);
                }}
                              >
                {/* Event Image (only if available) */}
                {event.event_image ? (
                  <CardMedia
                    component="img"
                    alt={event.event_name}
                    height="200"
                    image={event.event_image}
                    sx={{ objectFit: 'cover' }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: '200px',
                      backgroundColor: '#f5f5f5',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#aaa',
                    }}
                  >
                    No Image Available
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                    {event.event_id}
                  </Typography><Typography variant="h6" component="div" gutterBottom>
                    {event.event_name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Date:</strong> {new Date(event.event_date).toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>City:</strong> {event.event_city}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {events.length === 0 && (
        <Grid container spacing={3}>
          <Grid item>
            <MKButton
              variant="contained"
              color="primary"
              onClick={() => navigate("/createnewevent")} // This will navigate to /createnewevent
            >
              No Events Found. Create an Event
            </MKButton>
          </Grid>
        </Grid>
      )}
      <EFCart/>
      {/* <CreateVendorsButton/> */}
    </BaseLayout>
  );
}

export default MyEvents;
