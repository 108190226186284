import { Delete } from '@mui/icons-material';
import { Box, Button, CardContent, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import EFBanner from '../../../components/EFBanner';
import { useCart } from './CartContext';

const CartPage = () => {
  const { cart, getTotalPrice, removeFromCart, updateQuantity, loading } = useCart();
  const [localCart, setLocalCart] = useState(cart);

  // Synchronize localCart with the context cart
  useEffect(() => {
    setLocalCart(cart);
  }, [cart]);

  // Handle the quantity update in both local state and Firestore
  const handleQuantityChange = async (itemId, newQuantity) => {
    // Update the local state first
    setLocalCart(localCart.map(item =>
      item.id === itemId ? { ...item, quantity: newQuantity } : item
    ));

    // If quantity is valid, update Firestore as well
    if (newQuantity > 0) {
      await updateQuantity(itemId, newQuantity);  // Update Firestore
    } else {
      // Optionally, remove the item if quantity is set to 0
      removeFromCart(itemId);
    }
  };

  if (loading) {
    return <Typography>Loading your cart...</Typography>;
  }

  return (
    <div>
      <Grid 
        container 
        justifyContent="center" 
        alignItems="center" 
        sx={{ paddingY: 2 }}
      >
        <Grid 
          item 
          xs={12} 
          sm={12} 
          md={12} 
          sx={{ display: 'flex', justifyContent: 'center' }}
          mt={8}
        >
          <EFBanner text="Welcome to Our Website! Check out our latest offers!" speed={20} />
        </Grid>
      </Grid>

      <Box 
        sx={{
          backgroundColor: '#F2F2F2',   // EF White
          borderRadius: 2, // Rounded corners
          boxShadow: 3, // Raised shadow
          p: 3, // Padding
          mx: 'auto', // Center horizontally
          width: { xs: '95%', sm: '80%', md: '70%' }, // Responsive width
          mt: 0, // Start at the very top (adjust if there's a header)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" gutterBottom>Your Cart</Typography>

            {cart.length === 0 ? (
              <Typography>No items in the cart.</Typography>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* Loop through each cart item and display it */}
                {localCart.map((item) => (
                  <div
                    key={item.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '15px',
                      backgroundColor: '#fff', // White background for each item
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      justifyContent: 'space-between',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Optional shadow effect for separation
                    }}
                  >
                    {/* Image Display */}
                    {item.imageUrl && (
                      <CardMedia
                        component="img"
                        alt={item.name}
                        image={item.imageUrl}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '8px',
                        }}
                      />
                    )}

                    {/* Item Content */}
                    <CardContent style={{ flex: 1, marginLeft: '20px' }}>
                      <Typography variant="h6">{item.name}</Typography>
                      <Typography variant="body1">Price: ${item.price}</Typography>
                      
                      {/* Quantity input */}
                      <TextField
                        label="Quantity"
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                        sx={{ width: '80px' }}
                      />
                    </CardContent>

                    {/* Remove from Cart Button */}
                    <Button
                    color='accent'
                      onClick={() => removeFromCart(item.id)}
                      sx={{ marginLeft: '10px' }}
                    >
                      <Delete />
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </Grid>

          {/* Total Price Section (Right Column) */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: 2,
                borderRadius: 2,
                boxShadow: 2,
                position: 'sticky',
                top: 0,
              }}
            >
              <Typography variant="h5" gutterBottom>Total</Typography>
              <Typography variant="h6">
                ${isNaN(getTotalPrice()) ? 0 : getTotalPrice().toFixed(2)}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{ width: '100%', marginTop: '20px' }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CartPage;
