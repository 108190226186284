// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Eventflow.app React components
import MKTypography from "../src/components/MKTypography";

// Images
import logoEF from "../src/assets/images/ef_logo_white.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Eventflow.app",
    image: logoEF,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/people/Eventflow/61559047094216/?_rdr",
    },
    {
      icon: <TwitterIcon />,
      link: "https://x.com/eventflow-app",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/",
    },
  ],
  menus: [
    /* 
    {
      name: "company",
      items: [
        { name: "about us", href: "https://www.creative-tim.com/presentation" },
        { name: "blog", href: "https://www.creative-tim.com/blog" },
        { name: "parent company", href: "https://krystalmauri.com" },
      ],
    },

    {
      name: "legal",

              { name: "freebies", href: "https://www.creative-tim.com/templates/free" },

      items: [
        { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
        { name: "privacy policy", href: "https://www.creative-tim.com/privacy" },
        { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
      ],
    }, */
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Eventflow.app by{" "}
      <MKTypography
        component="a"
        href="https://www.krystalmauri.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Krystal Mauri, LLC.
      </MKTypography>
      .
    </MKTypography>
  ),
};
