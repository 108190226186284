import React from 'react';

interface VideoPlayerProps {
  videoUrl: string;
}

const EFVideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl }) => {
  const isYouTubeVideo = (url: string): boolean => {
    return url.includes("youtube.com/watch?v=") || url.includes("youtu.be/");
  };

  return (
    <>
      {isYouTubeVideo(videoUrl) ? (
        <iframe
          width="100%"
          height="350"
          src={videoUrl.replace("watch?v=", "embed/").replace("youtu.be/", "youtube.com/embed/")}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
        />
      ) : (
        <video width="100%" height={450} controls>
          <source src={videoUrl} type="video/mp4" />
          <source src="mov_bbb.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )}
    </>
  );
};

export default EFVideoPlayer;
