/*
=========================================================
* Eventflow.app React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Eventflow.app React components
import MKBox from "../../../../components/MKBox";

// Eventflow.app React examples
import CenteredBlogCard from "../../../../examples/Cards/BlogCards/CenteredBlogCard";
import DefaultInfoCard from "../../../../examples/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Increased Visibility"
                    description="Connect with clients who are actively searching for top-notch event services. Our platform is 
                    designed to highlight your unique offerings, making it easier for potential clients to find and book you."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Targeted Audience"
                    description="Connect with clients who are actively searching for top-notch event services. Our platform 
                    is designed to highlight your unique offerings, making it easier for potential clients to find and book you."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Comprehensive Profiles"
                    description="Create a detailed vendor profile complete with images, service descriptions, pricing, and 
                    customer reviews. Stand out from the competition by showcasing what makes your business special."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Marketing Support"
                    description="Take advantage of our extensive marketing efforts, including featured posts on our social media 
                    channels (TikTok, Instagram, and Facebook) and promotional campaigns.."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://eventflow.app/gallery_gen/e6f94fd8f2f013b2c15a02a759b98632_fit.png"
              title="Join the Eventflow Community!"
              description="Take advantage of our extensive marketing efforts, including featured posts on our social media
               channels (TikTok, Instagram, and Facebook) and promotional campaigns."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "become a vendor",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
