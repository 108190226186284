

// @mui material components
import Autocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';

// Eventflow.app React components
import MKBox from "../../components/MKBox";
import MKSocialButton from "../../components/MKSocialButton";
import MKTypography from "../../components/MKTypography";


// Eventflow.app React examples
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";
import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Presentation page sections
import Information from "../Presentation/sections/Information";

// Presentation page components
import { city_data } from '../../ef-constants';
import BuiltByDevelopers from "../Presentation/components/BuiltByDevelopers";
// Routes
import footerRoutes from "../../footer.routes";

// Images
import bgImage from "../../assets/images/bg1.jpg";



function Presentation() {

  const [ city, setCity ] = useState("");


  const handleCityChange = (event, value) => {
    setCity(value);
    console.log(value); // Log the new value directly
  };

  return (
    <>
      
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              className="title"
            >
              Eventflow.app
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Your ultimate platform for orchestrating unforgettable weddings and milestone birthday celebrations.
            </MKTypography>

            <div>
            <Autocomplete
  disablePortal
  id="combo-box-demo"
  options={city_data.map((city) => city.city_name)}
  sx={{ width: 300, backgroundColor: "#FFF", marginY: "5px" }}
  onChange={handleCityChange} // Update here
  renderInput={(params) => <TextField {...params} label="Nearest Major City" />}
/>
    <Link  
 className="btn btn-pink"
 role="button"
 to={`/vendors/${city}`} 
 style={{ textAlign: 'center', padding: '10px 20px', margin: '10px auto', width:'50%', display: 'block', backgroundColor: 'white' }}
> 
 See Vendors
</Link>
    </div>
          </Grid>
          

 
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
                <Information />

        
       {/* 
       <Counters />
       <DesignBlocks />

        <Pages />
        */
      }
       
       <Container>
          <BuiltByDevelopers />
        </Container>

        <Container >
          {// TODO: this 
          }
          <Grid mt={2} container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Discover and Connect"
                description="Tap into our curated network of vendors for weddings and milestone 
                birthdays. Choose your ideal venue, caterer, and more with ease."
                action={{
                  type: "external",
                  route: "https://www.eventflow.app/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plan and Organize"
                description="Use our innovative tools to schedule, budget, and oversee your event, 
                ensuring a delightful planning experience from anywhere, anytime."
                action={{
                  type: "internal",
                  route: "/createnewevent",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Celebrate and Share"
                description="Experience the magic of your dream event and share the joy with everyone who matters."
                action={{
                  type: "internal",
                  route: "/myevents",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {// <Testimonials /> <Download />
}

        
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Start Planning!
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20out%20Eventflow%20app%20made%20by%20%40KrystalMauriLLC%20%%20%%20%&amp;url=https%3A%2F%2Fwww.eventflow.app"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.eventflow.app"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.eventflow.app"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
