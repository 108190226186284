// AutoCompleteField.tsx

import { Autocomplete, TextField } from "@mui/material";
import React from "react";

// Define the type for the component props
interface EFMajorCityLookupProps {
  options: string[]; // The options list should be an array of strings
  label: string; // Label for the input field
  onChange: (value: string | null) => void; // Callback when the value changes
}

const EFMajorCityLookup: React.FC<EFMajorCityLookupProps> = ({ options, label, onChange }) => {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      sx={{ width: "100%" }}
      onChange={(event, newValue) => {
        onChange(newValue); // Call the callback with the new value
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default EFMajorCityLookup;
