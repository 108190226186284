import { useParams } from 'react-router';
import Footer from "../../LandingPages/Author/sections/Footer";

// Routes
import { Favorite } from '@mui/icons-material';
import { Box, CircularProgress, Grid, IconButton, Snackbar } from '@mui/material';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import EFAddToEvent from '../../../components/EFAddToEvent';
import { auth, firestore } from '../../../firebase';
import Profile from "../../Vendors/VendorPage/sections/Profile";
import BookingForm from '../Booking';
import SimilarVendorsCarousel from '../SimilarVendorsCarousel';
import ContactVendor from './sections/ContactVendor';
import InventoryPreview from './sections/InventoryPreview';
import KeyInfo from './sections/KeyInfo';
import Pricing from './sections/Pricing';
import Reviews from './sections/Reviews';

const VendorPage: React.FC = () => {
  let { id } = useParams();
  const [vendor, setVendor] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Function to fetch the vendor data and update view count if needed
  async function setVendorPage() {
    const docRef = doc(firestore, "vendors", id);
    console.log('passed param ', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document venue data:", docSnap.data());
      const result = docSnap.data();
      console.log('my result ', result);
      setVendor(result);
      setLoading(false);

      // If the user is authenticated and is NOT the owner, update the view count
      if (auth.currentUser && result?.vendor_id !== auth.currentUser.uid) {
        try {
          const vendorRef = doc(firestore, "vendors", id);

          // Increment the view count field in Firestore
          await updateDoc(vendorRef, {
            vendor_viewCount: (result.vendor_viewCount || 0) + 1,
          });

          console.log('View count updated');
        } catch (error) {
          console.error('Error updating view count:', error);
        }
      }
    } else {
      console.log("No such document!");
    }
  }

  useEffect(() => {
    setVendorPage();
  }, [id]);

  // Handle adding vendor to favorites
  const handleAddToFavorites = async () => {
    try {
      const favoritesDocRef = doc(firestore, 'users', auth.currentUser?.uid, 'Favorites', id);

      // Add or update the vendor in the Favorites collection
      await setDoc(favoritesDocRef, {
        vendor_id: vendor?.vendor_id,
        vendor_name: vendor?.vendor_name,
        vendor_type: vendor?.vendor_type,
        added_at: new Date(), // Timestamp of when added
      }, { merge: true });

      setSuccessMessage('Vendor added to favorites successfully!');
    } catch (error) {
      setErrorMessage(`Error adding vendor to favorites: ${error.message}`);
    }
  };

  if (loading) {
    console.log('Profile component loading....');
    return <CircularProgress />; // Display loading message while fetching data
  }

  return (
    <>
      <div>
        <Grid container justifyContent="center" alignItems="center" sx={{ padding: 1 }}>
          <Grid item xs={8} sm={8} md={8} mt={10}>
            <InventoryPreview id={id} />
          </Grid>

          <Grid item xs={10} sm={10} md={10} sx={{ display: 'flex' }} mt={8}>
            <h2 className="vendorTitle">{vendor?.vendor_name}  </h2>
            <IconButton color="secondary" aria-label="favorite this vendor" onClick={handleAddToFavorites}>
              <Favorite />
            </IconButton>
            { (auth != null) && (
                          <EFAddToEvent vendorId={id} vendorType={vendor?.vendor_type} />


            )}
          </Grid>
        </Grid>

        <Box sx={{ backgroundColor: '#F2F2F2', boxShadow: 3, p: 3, mx: 'auto', width: { xs: '95%', sm: '80%', md: '85%' }, mt: 0 }}>
          <Grid container pt={0} lg={12}>
            <Profile
              id={id}
              avi={vendor?.vendor_pictureUrl}
              name={vendor?.vendor_name}
              thisRating={vendor?.vendor_rating}
              thisType={vendor?.vendor_type}
              thisDescription={vendor?.vendor_description}
              vendor_own={vendor?.vendor_own}
              video={vendor?.vendor_featured_video}
              email={vendor?.vendor_email}
              tier={vendor?.vendor_tier}
              capacity={vendor?.vendor_capacity}
            />
            {vendor?.vendor_tier > 0 && (
              <>
                <KeyInfo id={id} addOns={vendor?.vendor_addOns} amenities={vendor?.vendor_amenities} />
                {vendor?.vendor_tier > 1 && <Pricing id={id} />}
                {vendor?.vendor_tier === 3 && <BookingForm vendorId={id} vendorEmail={vendor?.vendor_email} />}
                <ContactVendor id={id} address={vendor?.vendor_location} phone={vendor?.vendor_phone} name={vendor?.vendor_name} />
              </>
            )}

            <Reviews id={id} />
            <SimilarVendorsCarousel vendorType={vendor.vendor_type} city={vendor.vendor_majorCity} />
          </Grid>
          <Footer />
        </Box>

        {/* Snackbar for success */}
        <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')} message={successMessage} />

        {/* Snackbar for error */}
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')} message={errorMessage} />
      </div>
    </>
  );
};

export default VendorPage;
