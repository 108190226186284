/*
=========================================================
* Eventflow.app React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import bgBack from "../../../assets/images/rotating-card-bg-back.jpeg";
import bgFront from "../../../assets/images/rotating-card-bg-front.jpeg";



// Eventflow.app React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";


// Eventflow.app React examples
import DefaultFooter from "../../../examples/Footers/DefaultFooter";

// About Us page sections
import Featuring from "../AboutUs/sections/Featuring.js";
import Information from "../AboutUs/sections/Information.js";
import Newsletter from "../AboutUs/sections/Newsletter.js";
import Team from "../AboutUs/sections/Team.js";

// Routes
import { city_data } from "../../../ef-constants";
import footerRoutes from "../../../footer.routes.js";

// Images
import bgImage from "../../../assets/images/bg1.jpg";

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { collection, getDocs, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../../../firebase.ts';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';



function AboutUs() {
  const [venues, setEntries] = useState([]);
  const [ search, setSearch ] = useState("");
  const [ city, setCity ] = useState("");
  

  const bg = "https://server54.web-hosting.com:2083/cpsess8127163539/viewer/home%2fkryspknt%2feventflow.app%2fimages/bg1.jpg";


  async function getVendors(){
    //  const q = query(collection(firestore, "Vendors"), where("vendor_type", "==", "Designer"));
      const q = query(collection(firestore, "Saint Louis"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const entries = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log(doc.id, "all => ", doc.data());
    console.log('my entries ', entries);
    setEntries(entries);
  });
  }

  useEffect(() => {
    getVendors();

  }, []);

  const handleCityChange = (event, value) => {
    setCity(event.target.value);
    console.log(city);
  };


  return (
    <>
     
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Your Specialist in Event Planning
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            Discover EventFlow, your ultimate platform for orchestrating unforgettable weddings 
            and milestone birthday celebrations. Available everywhere, our 
            app connects you effortlessly with exceptional vendors and manages every detail with precision.
            </MKTypography>
            <div>
        <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={city_data.map((city) => (city.city_name))}
      sx={{ width: 300, backgroundColor: "#FFF" }}
      renderInput={(params) => <TextField {...params} label="Nearest Major City" onChange={handleCityChange} onSelect={handleCityChange}/>}
      
    />

    </div>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="https://www.facebook.com/profile.php?id=61559047094216" mr={3}>
                <i className="fab fa-facebook" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/eventflow.app/" mr={3}>
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="white" href="https://www.tiktok.com/@eventflow.app" mr={3}>
                <i className="fab fa-tiktok" />
              </MKTypography>
            
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <h1>What We&apos;re Looking For</h1>




<p>&bull;Caterers/Bakers</p>

<p>&bull;Bartending Services</p>

 

<h3>&bull;</h3>
<p>&bull;Security Services</p>
<p>&bull;Staffed Coat Check Services</p>

 

<h3>Miscellaneous</h3>
<p>&bull;Event Insurance</p>

<p>&bull;Waste Management</p>

<p>&bull;Gift/Favor Suppliers</p>

<Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
              <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Venue and Logistics
                  </>
                }
                description=""
              />

              <RotatingCardBack
                image={bgBack}
                title="Sign Up Today"
                description="Venue Owners &bull; Transportation Companies"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "join now",
                }}
              />
            </RotatingCard>

            </Grid>
            <Grid item xs={12} md={6}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title="Event Design and Setup"
                description=""
              />
              <RotatingCardBack
                image={bgBack}
                title="Sign Up Today"
                description="Event Planners &bull; Event Curators &bull; Event Decorators &bull; Equipment Rental Companies &bull; Audio/Visual and Technical Services"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "join now",
                }}
              />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title="Entertainment"
                description=""
              />
              <RotatingCardBack
                image={bgBack}
                title="Sign Up Today"
                description="DJs &bull; Hosts &bull; Promoters &bull; Photographers &bull; Videographers &bull;"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "join now",
                }}
              />
            </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title="Attendee Services"
              />
              <RotatingCardBack
                image={bgBack}
                title="Sign Up Today"
                description="Security Services &bull; Bartender"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "join now",
                }}
              />
            </RotatingCard>
            
            </Grid>
            </Grid>
            </Grid>
            </Grid>

        <Team />
        <Featuring />
        <Newsletter />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker defaultValue={dayjs('2022-04-17')} />
        </LocalizationProvider>

      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
