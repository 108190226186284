import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore methods
import PropTypes from 'prop-types'; // Import PropTypes
import { createContext, useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../../firebase';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = auth.currentUser;

  // Fetch user's cart from Firestore
  const fetchCart = async () => {
    if (!user) return;
    try {
      const userDocRef = doc(firestore, 'users', user.uid); // Reference to the user's cart
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData?.cart) {
          setCart(userData.cart);
        }
      }
    } catch (error) {
      console.error('Error fetching cart:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add item to the cart and Firestore
  const addToCart = async (item) => {
    if (!user) return;
    try {
      const userDocRef = doc(firestore, 'users', user.uid);

      // Add the item to the cart in Firestore using arrayUnion
      await updateDoc(userDocRef, {
        cart: arrayUnion(item),
      });

      // Update local cart state
      setCart((prevCart) => [...prevCart, item]);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  // Remove item from the cart and Firestore
  const removeFromCart = async (itemId) => {
    if (!user) return;
    try {
      const userDocRef = doc(firestore, 'users', user.uid);

      // Remove the item from the cart in Firestore using arrayRemove
      await updateDoc(userDocRef, {
        cart: arrayRemove({ id: itemId }),
      });

      // Remove item from local cart state
      setCart((prevCart) => prevCart.filter(item => item.id !== itemId));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  // Get total price of all items in the cart
const getTotalPrice = () => {
  return cart.reduce((total, item) => {
    const price = parseFloat(item.price); // Convert price to a number
    if (!isNaN(price)) {  // Only add the price if it's a valid number
      return total + price;
    }
    return total; // If price is not a valid number, do not add it
  }, 0);
};


  // Fetch cart data on component mount and when user changes
  useEffect(() => {
    fetchCart();
  }, [user]);

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, getTotalPrice, loading }}>
      {children}
    </CartContext.Provider>
  );
};

// PropTypes for validation
CartProvider.propTypes = {
  children: PropTypes.node.isRequired,  // Children should be a node and is required
};

export default CartProvider;
