import { useIonViewWillEnter } from '@ionic/react';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import TransparentBlogCard from '../examples/Cards/BlogCards/TransparentBlogCard';

// Vendor interface to type the posts correctly
interface Vendor {
  vendor_id: string;
  vendor_pictureUrl: string;
  vendor_name: string;
  vendor_type: string;
}

interface EFCardProps {
  posts: Vendor[];
}

const EFCard: React.FC<EFCardProps> = ({ posts }) => {
  const [slideSpace, setSlideSpace] = useState(0);

  // Hook to handle some slide animation effect
  useIonViewWillEnter(() => {
    setSlideSpace(5);
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        {/* Loop through the posts array and render a Grid item for each vendor */}
        {posts.map((vendor, i) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={vendor.vendor_id || i}>
            <TransparentBlogCard
              image={vendor?.vendor_pictureUrl || "https://i.ytimg.com/vi/5aJPNHnkwjw/maxresdefault.jpg"}
              title={vendor?.vendor_name}
              description={vendor?.vendor_type}
              action={{
                type: "internal",
                route: `/vendor/${vendor.vendor_id}`,
                color: "info",
                label: "",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EFCard;
