import * as firebase from 'firebase/app';
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import 'firebase/storage';
import { getStorage, ref } from 'firebase/storage';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional


//  DEV 
///*
const firebaseConfig = {
    apiKey: "AIzaSyAn-Upj0XlPC51K7M6MnQxO4k4Lsi9DFH4",
    authDomain: "eventflow-dev-1ffc9.firebaseapp.com",
    projectId: "eventflow-dev-1ffc9",
    storageBucket: "eventflow-dev-1ffc9.appspot.com",
    messagingSenderId: "220263589159",
    appId: "1:220263589159:web:1b747c6347ea4e96ae3502",
    measurementId: "G-ZEV40LW70Q"
  };

 // */
/*
//  UAT
const firebaseConfig = {
  apiKey: "AIzaSyDD36wKp88SSdB6gOw714SI13Blf95Maxo",
  authDomain: "eventflow-testing.firebaseapp.com",
  projectId: "eventflow-testing",
  storageBucket: "eventflow-testing.firebasestorage.app",
  messagingSenderId: "65879937302",
  appId: "1:65879937302:web:0d664980a36ee29f728037",
  measurementId: "G-RY527MYLWG"
};

*/

  const app = firebase.initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const firestore = getFirestore(app);
  export const storage = getStorage();
  export const storageRef = ref(storage);
  export const functions = getFunctions(app);
  
  
  export function anonSignIn(){
    signInAnonymously(auth)
  .then(() => {
    console.log('User signed in anonymously.');
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
  }

  

  export function logout() {
    signOut(auth).then(() => {
        // Sign-out successful.
       // console.log('user logged out');
        
            }).catch((error) => {
        // An error happened.
        console.log('error user logging out');

      });
  }